<template>
  <v-row>
    <v-col style="max-width: 90px">
      <v-card class="pa-1 shadow-md">
        <UserPhoto
          :id="message.sender"
          photoSize="small"
          size="60"
          rounded
          tile
        />
      </v-card>
    </v-col>
    <v-col>
      <div class="mb-1 font-weight-bold">{{ $store.getters['users/userData'](message.sender).firstName }} {{ $store.getters['users/userData'](message.sender).lastName }}:</div>
      <div class="body-1 pre-wrap">
        {{ message.message }}
      </div>

      <Attachments :message="message" />
    </v-col>
    <v-col class="text-right" style="max-width: 150px">
      <span class="grey--text">{{ message.created | fromNow }} ago</span>
    </v-col>
  </v-row>
</template>

<script>
import Attachments from './Attachments'

export default {
  props: {
    message: Object
  },

  components: {
    Attachments
  }
}
</script>