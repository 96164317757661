<template>
  <div class="flex-grow-1 pb-10">
    <AdminTitle title="Inbox">
      <template v-slot:actions>
        <div class="d-flex gap-12 align-center">
          <v-btn
            @click="getReplies($route.params.id)"
            :loading="status.gettingReplies"
            icon
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </div>
      </template>
    </AdminTitle>

    <v-card v-if="message" class="shadow-lg">
      <v-row no-gutters>
        <v-col :style="{maxWidth : '100px'}">
          <div class="pa-7 text-center">
            <div class="mt-4">
              <back :to="{ name: 'Message' }" />
            </div>
          </div>
        </v-col>

        <v-col style="border-left: 1px solid rgba(10,30,35,0.1)">
          <div class="pa-10">
            <div class="d-flex align-center">
              <h5 class="text-h5 font-weight-black">{{ message.subject }}</h5>

              <v-spacer></v-spacer>

              <v-btn v-if="!archived" @click="starMessage(message)" :color="starred ? 'yellow darken-1' : '#dadada'" icon large>
                <v-icon size="26">{{ starred ? 'mdi-star' : 'mdi-star-outline' }}</v-icon>
              </v-btn>
              
              <v-btn @click="archiveMessage(message)" color="#dadada" icon large>
                <v-icon size="26">{{ archived ? 'delete-restore' : 'mdi-trash-can-outline' }}</v-icon>
              </v-btn>
            </div>

            <div v-if="message.job">
              <router-link
                :to="{ name: 'JobProfile', params: { id: message.job } }"
                class="caption primary--text text-decoration-none underline-on-hover"
              >View Job Listing <v-icon color="info" x-small>mdi-chevron-right</v-icon> </router-link>
            </div>

            <div class="d-flex mt-5 gap-20">
              <v-card class="rounded-pill shadow-label">
                <v-card-text class="pa-2 d-flex align-center">
                  <div>
                    <UserPhoto
                      :id="message.sender"
                      photoSize="small"
                      size="40"
                    />
                  </div>

                  <div class="ml-3 mr-5">
                    <div class="font-weight-bold heading-font primary--text">
                      {{ $store.getters['users/userData'](message.sender).firstName }} {{ $store.getters['users/userData'](message.sender).lastName }}
                    </div>
                    <div class="mt-n1 caption">sender</div>
                  </div>
                </v-card-text>
              </v-card>
              
              <v-card 
                :to="{ name: 'TalentProfile', params: { id: message.recipient } }" 
                class="rounded-pill shadow-label"
              >
                <v-card-text class="pa-2 d-flex align-center">
                  <div>
                    <UserPhoto
                      :id="message.recipient"
                      photoSize="small"
                      size="40"
                    />
                  </div>

                  <div class="ml-3 mr-5">
                    <div class="font-weight-bold heading-font primary--text">
                        {{ $store.getters['users/userData'](message.recipient).firstName }} {{ $store.getters['users/userData'](message.recipient).lastName }}
                    </div>
                    <div class="mt-n1 caption">recipient</div>
                  </div>
                </v-card-text>
              </v-card>
            </div>

            <v-divider class="my-7"></v-divider>

            <v-file-input
              v-model="attachments"
              @change="filesSelected($event)"
              ref="attachments"
              class="d-none"
              multiple
            ></v-file-input>

            <div 
              v-if="canMessage()"
              class="d-flex flex-wrap gap-10 align-center mt-7 mb-7"
            >
              <div v-if="files.length" class="full-width d-flex flex-wrap align-center gap-12 pb-5">
                <file-block
                  v-for="(file, i) in files"
                  :key="i"
                  :file="file"
                  @remove="$store.commit('message/removeAttachment', i)"
                />
              </div>

              <v-btn @click="$refs.attachments.$refs.input.click()" color="rgba(16,53,62,0.1)" icon outlined>
                <v-icon color="primary">mdi-paperclip</v-icon>
              </v-btn>

              <v-form class="flex-grow-1" ref="replyForm">
                <v-textarea
                  v-model="data.reply"
                  :rules="[rules.required]"
                  placeholder="Type message"
                  hide-details
                  auto-grow
                  outlined
                  rows="1"
                ></v-textarea>
              </v-form>

              <v-btn 
                @click="validateForm()"
                :loading="status.replying"
                color="accent gradient"
              >Send</v-btn>
            </div>

            <div v-if="replies.length">
              <div
                v-for="reply in orderBy(replies, 'created', 'desc')"
                :key="reply.id"
              >
                <message-block :message="reply" />
                <v-divider class="my-5 ml-auto" style="max-width: calc(100% - 90px); opacity: 0.5;"></v-divider>
              </div>
            </div>

            <message-block :message="message" />
          </div>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import rules from '@/rules'
import MessageBlock from './MessageBlock'
import orderBy from 'lodash/orderBy'
import FileBlock from './FileBLock'

export default {
  metaInfo: {
    title: 'Message'
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return{
      rules,
      orderBy,
      attachments: [],
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      status: state => state.message.status,
      data: state => state.message.data,
      replies: state => state.message.replies,
      user: state => state.user.user,
      files: state => state.message.attachments,
    }),

    message: function () {
      return this.$store.getters['message/message'](this.$route.params.id)
    },

    starred: function () {
      if (this.user && this.user.userid == this.message.sender) {
        return !!this.message.senderStarred
      }
      else {
        return !!this.message.recipientStarred
      }
    },

    archived() {
      if (this.user) {
        if (this.user.userid == this.message.sender) {
          return !!this.message.senderArchived
        }
        else {
          return !!this.message.recipientArchived
        }
      }

      return false
    }
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'status.uploading': function (val) {
      if (!val) {
        this.submitForm()
      }
    }
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    MessageBlock,
    FileBlock,
  },

  /*------------------------------------------------------------------------------
    * METHODS
    *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('message', [
      'getMessage',
      'readMessage',
      'replyMessage',
      'getReplies',
      'starMessage',
      'archiveMessage',
      'uploadFiles'
    ]),

    canMessage() {
      if (!this.archived) {
        if (this.user) {
          if (this.user.role == 'employer') {
            return this.$store.getters['pricing/withActiveSub']
          }
          else if (this.user.role == 'jobseeker') {
            return true
          }
          else {
            return true
          }
        }
        else {
          return false
        }
      }
      else {
        return false
      }
    },

    validateForm() {
      if (this.$refs.replyForm.validate()) {
        if (this.files.length) {
          this.$store.commit('message/uploadingState', this.files.length)
          this.uploadFiles()
          this.$store.commit('message/replyingState', true)
        }
        else {
          this.submitForm()
        }
      }
    },

    submitForm() {
      Promise.all([
        this.replyMessage(this.message)
      ])
      .then(() => {
        this.$refs.replyForm.reset()
      })
    },

    filesSelected(e) {
      e.forEach(file => {
        this.$store.commit('message/insertAttachments', file)
      })

      this.attachments = []
    },
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    this.getMessage(this.$route.params.id)
    this.getReplies(this.$route.params.id)
    if (this.message) this.readMessage(this.message)
  }
}
</script>

<style lang="scss">

.pre-wrap {
  white-space: pre-line;
}

html {
  scroll-behavior: smooth;
}

</style>