<template>
  <div class="mt-5 d-flex flex-wrap gap-7" v-if="attachments.length">
    <v-card
      v-for="file in attachments"
      :key="file.id"
      @click="$downloadFile('attachments', file.file)"
      width="250"
      outlined
      hover
    >
      <v-card-text class="d-flex gap-7 align-center">
        <v-icon :color="fileIcon(file.type, true)" left>{{ fileIcon(file.type) }}</v-icon> 
        <div class="d-inline-block text-truncate" style="max-width: 150px;">
          <span>{{ file.file | fileName }}</span>
          <span class="caption info--text"><br> {{ file.size | fileSize }} ({{ file.type }})</span>
        </div>
        <v-btn
          class="ml-auto"
          depressed
          x-small
          fab
        >
          <v-icon small>mdi-download</v-icon>
        </v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      attachments: []
    }
  },

  props: {
    message: Object
  },

  methods: {
    fileIcon(type, color = false) {
      let icons = [
        {
          icon: 'mdi-file-image-outline',
          color: 'green accent-4',
          types: [
            'image/jpeg',
            'image/png'
          ]
        },
        {
          icon: 'mdi-file-pdf-outline',
          color: 'red accent-3',
          types: [
            'application/pdf',
          ]
        },
        {
          icon: 'mdi-file-code-outline',
          color: 'amber darken-2',
          types: [
            'image/svg+xml',
          ]
        },
        {
          icon: 'mdi-zip-box-outline',
          color: 'blue-grey darken-1',
          types: [
            'application/x-zip-compressed',
          ]
        },
      ]

      let icon = icons.find(i => {
        return i.types.includes(type)
      })

      if (color) return icon ? icon.color : 'blue accent-3'
      else return icon ? icon.icon : 'mdi-file-document-outline'
    }
  },

  mounted() {
    if (this.message) {
      this.message.ref
      .collection('attachments')
      .get()
      .then(snapshot => {
        if (snapshot.size) {
          snapshot.forEach(doc => {
            let attachment = doc.data()
            attachment.id = doc.id
            attachment.ref = doc.ref
            this.attachments.push(attachment)
          })
        }
      })
    }
  }
}
</script>